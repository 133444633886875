import {Breadcrumb, Layout, message, Table, Popconfirm, Button, Card, Row, Modal} from "antd";
import {HomeOutlined, DeleteTwoTone, EditTwoTone, EyeTwoTone} from "@ant-design/icons";
import {FormattedMessage, useIntl} from "react-intl";
import columnSearchProps from "../columnSearchProps";
import {connect, useDispatch} from "react-redux";
import {useEffect, useState} from "react";

import {
    selectLoading,
    selectError,
    selectUsers,
    selectCurrentUser
} from "../../redux/user/userSelector";
import {listUsers, deleteUser, clearUserError, getUser, setLoading, clearLoading} from '../../redux/user/userActions';
import {Link} from "react-router-dom";
import Moment from "react-moment";
import {selectRoles} from "../../redux/role/roleSelector";
import {listRoles} from "../../redux/role/roleActions";


const {Content, Footer} = Layout;

const labelStyle = {width: '25%', textAlign: 'center'}
const valueStyle = {width: '75%', textAlign: 'left'}

const Users = (props) => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const {users, count, max, offset} = props.users;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userDetails, setUserDetails] = useState(<Card loading={true}> </Card>);

    const showModal = (id) => {
        setIsModalVisible(true);
        props.getUser(props.history, id).then((data) => {
            setUserDetails(<Card>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.first-name" defaultMessage="First Name"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.firstName}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.last-name" defaultMessage="last-name"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.lastName}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.email" defaultMessage="Email"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.email}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.job" defaultMessage="Job"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.job}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.company" defaultMessage="Company"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.company}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.phone" defaultMessage="Phone"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {data.phone}
                    </Card.Grid></Row>
                <Row gutter={16}>
                    <Card.Grid hoverable={false} style={labelStyle}>
                        <b><FormattedMessage id="label.role" defaultMessage="Role"/></b>
                    </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {(data.role ? props.role?.roles?.find((role) => role.id === data.role)?.authority : '')}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.enabled" defaultMessage="Enabled"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        {(data.enabled ? intl.formatMessage({id: 'label.yes'}) : intl.formatMessage({id: 'label.no'}))}
                    </Card.Grid></Row>
                <Row gutter={16}><Card.Grid hoverable={false} style={labelStyle}>
                    <b><FormattedMessage id="label.last-updated" defaultMessage="Last Updated"/></b>
                </Card.Grid>
                    <Card.Grid hoverable={false} style={valueStyle}>
                        <Moment format="YYYY-MM-DD HH:MM:SS">{data.lastUpdated}</Moment>
                    </Card.Grid></Row>


            </Card>)
        })
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'label.id'}),
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '5%',
            fixed: true,
            sorter: (a, b) => a.id - b.id,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: intl.formatMessage({id: 'label.actions'}),
            dataIndex: 'actions',
            align: 'center',
            width: '10%',
            fixed: true,
            render: (_, record) =>
                count >= 1 ? (
                    <div className="actionsIcons">
                        <Popconfirm title={intl.formatMessage({id: 'msg.confirm-delete'})}
                                    onConfirm={() => handleDelete(record.id)}>
                            <DeleteTwoTone twoToneColor="red"
                                           title={intl.formatMessage({id: 'title.label.actions.remove'}) + ' ' + intl.formatMessage({id: 'title.label.actions.user'})}/>
                        </Popconfirm>
                        <Link to={`/admin/users/${record.id}`}><EditTwoTone
                            title={intl.formatMessage({id: 'title.label.actions.edit'}) + ' ' + intl.formatMessage({id: 'title.label.actions.user'})}/></Link>
                        <EyeTwoTone onClick={() => {
                            showModal(record.id)
                        }} twoToneColor="green"
                                    title={intl.formatMessage({id: 'title.label.actions.show'}) + ' ' + intl.formatMessage({id: 'title.label.actions.user'}) + ' ' + intl.formatMessage({id: 'title.label.actions.details'})}/>
                    </div>
                ) : null,
        },
        {
            title: intl.formatMessage({id: 'label.first-name'}),
            dataIndex: 'firstName',
            key: 'firstName',
            width: '20%',
            fixed: false,
            sorter: (a, b) => a.firstName - b.firstName,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('firstName'),
        }, {
            title: intl.formatMessage({id: 'label.last-name'}),
            dataIndex: 'lastName',
            key: 'lastName',
            width: '20%',
            fixed: false,
            sorter: (a, b) => a.lastName - b.lastName,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('lastName'),
        }, {
            title: intl.formatMessage({id: 'label.email'}),
            dataIndex: 'email',
            key: 'email',
            width: '20%',
            fixed: false,
            sorter: (a, b) => a.email - b.email,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('email'),
        },
        {
            title: intl.formatMessage({id: 'label.username'}),
            dataIndex: 'username',
            key: 'username',
            width: '10%',
            fixed: false,
            sorter: (a, b) => a.username.length - b.username.length,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            ...columnSearchProps('username'),
        },
        {
            title: intl.formatMessage({id: 'label.enabled'}),
            dataIndex: 'enabled',
            key: 'enabled',
            width: '5%',
            fixed: false,
            sorter: (a, b) => a.enabled - b.enabled,
            sortDirections: ['ascend', 'descend'],
            filterMultiple: false,
            filters: [
                {
                    text: 'Yes',
                    value: true,
                },
                {
                    text: 'No',
                    value: false,
                },
            ],
            render: (text) => (text ? intl.formatMessage({id: 'label.yes'}) : intl.formatMessage({id: 'label.no'}))
        }

    ];

    useEffect(() => {

        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearUserError());
            };
            error();
        } else {
            if (props.role === null || props.role.length === 0) {
                dispatch(setLoading());
                let pagination = {}
                pagination['current'] = 1;
                pagination['pageSize'] = 9999999;
                let sorter = {}
                sorter['field'] = 'authority';
                dispatch(listRoles(props.history, pagination, {}, sorter));
                dispatch(clearLoading())
            } else {
                props.listUsers(props.history);
            }

        }
        // eslint-disable-next-line
    }, [props.error]);

    const handleChange = (pagination, filters, sorter) => {
        props.listUsers(props.history, pagination, filters, sorter);
    };

    const handleDelete = (id) => {
        props.deleteUser(props.history, id);
    };

    return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.admin" defaultMessage="Admin"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.users" defaultMessage="Users"/></span>
                    </Breadcrumb.Item>

                </Breadcrumb>
                <div className="site-layout-background" style={{padding: 24, minHeight: 360}}>
                    <Link to='/admin/users/new'><Button type="primary" style={{marginBottom: 16}}>
                        <FormattedMessage id="label.add-user" defaultMessage="Add a User"/>
                    </Button></Link>
                    <Table
                        locale={'es'}
                        bordered={true}
                        loading={props.loading}
                        rowKey={record => record.id}
                        responsive={true}
                        pagination={{
                            current: offset / max + 1,
                            showLessItems: true, pageSizeOptions: [10, 20, 50, 100],
                            pageSize: max,
                            defaultPageSize: max, total: count, showSizeChanger: true,
                            showTotal: ((total, range) => intl.formatMessage({
                                id: 'label.range-total'
                            }, {one: range[0], two: range[1], total: total}))
                        }}
                        size={'default'}
                        showHeader
                        columns={columns}
                        dataSource={users}
                        scroll={{y: '65vh', x: '100vw'}}
                        onChange={handleChange}
                    />
                </div>
                <Modal title={intl.formatMessage({id: 'label.user-details'})} visible={isModalVisible}
                       onOk={handleOk} onCancel={handleCancel} centered width={720}>
                    {userDetails}
                </Modal>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
    );
}

const mapStateToProps = state => ({
    loading: selectLoading(state),
    error: selectError(state),
    users: selectUsers(state),
    user: selectCurrentUser(state),
    role: selectRoles(state)
});

export default connect(mapStateToProps, {listUsers, deleteUser, clearUserError, getUser})(Users);
