import { Form, TreeSelect, Spin, InputNumber } from "antd";
import { useEffect, useState, useMemo } from "react";
import { useIntl } from "react-intl";
import { getGeoLevels, getRegionsByLevelAndLevelUp } from "../../utilData";
import { useDispatch } from "react-redux";
import { throwCustomErrorInvalidValueOfVariable } from "../utils/multiSourceUtils";
import { LoadingOutlined } from "@ant-design/icons";

/**
 * Select regionCode from a tree of options
 * @param props
 * @returns {*}
 * @constructor
 */
const FormItemsLocationsForProject = (props) => {
  const { form, edit, initialValues } = props;

  const intl = useIntl();
  const dispatch = useDispatch();

  const LoadingRegionCodeOptions = () => {
    return (
      <span>
        <Spin
          indicator={
            <LoadingOutlined style={{ fontSize: 24, color: "gray" }} spin />
          }
        />
        <span style={{ marginLeft: "10px" }}>
          {intl.formatMessage({
            id: "loading.FormItemLocationsForProject.regionCode.options.message",
          })}
        </span>
      </span>
    );
  };

  //states
  const [regionCodeOptionsTreeData, setRegionCodeOptionsTreeData] = useState([
    {
      title: <LoadingRegionCodeOptions />,
      value: "initialValue",
      selectable: false,
    },
  ]);
  const [treeExpandedKeys, setTreeExpandedKeys] = useState([]);

  const hasGeoRegionOptions = useMemo(() => {
    //TODO: add any other condition to indicate if options are loaded
    return !(
      (regionCodeOptionsTreeData?.length === 1 &&
        regionCodeOptionsTreeData?.[0]?.value === "initialValue") ||
      regionCodeOptionsTreeData?.length === 0
    );
  }, [regionCodeOptionsTreeData]);

  //geoLevels and optionsGeolevels
  //---------------------------------------
  const [geoLevels, setGeoLevels] = useState();
  useEffect(function ueSetGeoLevels() {
    dispatch(getGeoLevels()).then((data) => {
      if (data) {
        setGeoLevels(
          data.geoLevels.sort((a, b) =>
            a.idLevel === b.idLevel ? 0 : a.idLevel < b.idLevel ? -1 : 1
          )
        );
      }
    });
    //eslint-disable-next-line
  }, []);

  const [maxIdLevel, setMaxIdLevel] = useState(null);
  useEffect(
    function ueSetMaxIdLevel() {
      if (!geoLevels || geoLevels?.length === 0) return;

      setMaxIdLevel(() => {
        let idLevels = geoLevels?.map((geoLevel) => geoLevel.idLevel);
        if (!idLevels || idLevels?.length === 0) {
          throwCustomErrorInvalidValueOfVariable(
            "idLevels",
            { value: idLevels?.join(", ") },
            "setMaxIdLevel in useEffect hook function ueSetMaxIdLevel",
            [
              `it cannot be null or undefined and its length cannot be null`,
            ].join(" "),
            "FormItemsLocationsForProject"
          );
        }

        const maxIdLevel_ = Math.max(...[...new Set(idLevels)]);

        return maxIdLevel_;
      });
    },
    [geoLevels]
  );

  async function asyncGetGeoRegionsPartialRecursively(
    idLevel,
    levelUps,
    index = 0,
    geoRegions
  ) {
    return dispatch(getRegionsByLevelAndLevelUp(idLevel, levelUps[index])).then(
      (data) => {
        if (data?.geoRegions?.length > 0)
          geoRegions.push(
            ...data.geoRegions.map((geoRegion) => {
              geoRegion["idLevel"] = idLevel;
              return geoRegion;
            })
          );
        if (
          (index === 0 && levelUps.length === 0) ||
          index === levelUps.length - 1
        ) {
          return geoRegions;
        } else {
          return asyncGetGeoRegionsPartialRecursively(
            idLevel,
            levelUps,
            index + 1,
            geoRegions
          );
        }
      }
    );
  }

  async function asyncGetGeoRegionsRecursively(
    idLevelMax,
    levelUpFilter = undefined,
    idLevel = 0,
    levelUps = [],
    geoRegions = []
  ) {
    if (idLevelMax === 0) {
      if (geoRegions.length === 0 && idLevel === 0) {
        return getRegionsByLevelAndLevelUp(0, null)()
          .then((data) => {
            if (data?.geoRegions?.length > 0)
              geoRegions.push(
                ...data.geoRegions.map((geoRegion) => {
                  geoRegion["idLevel"] = 0;
                  return geoRegion;
                })
              );
            return geoRegions;
          })
          .then((geoRegions) => {
            const nextLevelUps =
              levelUpFilter !== undefined
                ? levelUps.filter((levelUp) => {
                    return levelUpFilter.includes(levelUp);
                  })
                : levelUps;
            return asyncGetGeoRegionsRecursively(
              idLevelMax,
              levelUpFilter,
              idLevel + 1,
              nextLevelUps,
              geoRegions
            );
          });
      } else {
        return geoRegions;
      }
    } else {
      if (idLevel > idLevelMax) {
        return geoRegions;
      } else {
        return asyncGetGeoRegionsPartialRecursively(
          idLevel,
          levelUps,
          0,
          geoRegions
        ).then((geoRegions) => {
          let nextLevelUps = [];
          nextLevelUps.push(
            ...geoRegions.map((geoRegion) => geoRegion.regionCode)
          );
          nextLevelUps = nextLevelUps.filter((nextLevelUp) =>
            levelUpFilter !== undefined
              ? levelUpFilter.includes(nextLevelUp)
              : true
          );
          return asyncGetGeoRegionsRecursively(
            idLevelMax,
            levelUpFilter,
            idLevel + 1,
            nextLevelUps,
            geoRegions
          );
        });
      }
    }
  }

  const node2item = (node) => {
    const defaultSelectable = true;
    const defaultIsLeaf = node.idLevel === maxIdLevel - 1;
    const defaultDisabled = false;
    return {
      title: node.name,
      value: node.regionCode,
      id: node.regionCode,
      pId: node.regionCodeLevelUp,
      selectable: defaultSelectable,
      isLeaf: defaultIsLeaf,
      disabled: defaultDisabled,
      key: node.regionCode,
      idLevel: node.idLevel,
    };
  };

  const initializeRegionCodeOptionsTreeDataRecursivelyNew = () => {
    asyncGetGeoRegionsRecursively(0).then((geoRegions) => {
      setRegionCodeOptionsTreeData(() => {
        return geoRegions
          .map((geoRegion) => node2item(geoRegion))
          .sort((a, b) =>
            a.title === b.title ? 0 : a.title < b.title ? -1 : 1
          );
      });
    });
  };

  const initializeRegionCodeOptionsTreeDataRecursivelyEdit = (
    idLevelMax,
    levelUpFilter
  ) => {
    asyncGetGeoRegionsRecursively(idLevelMax, levelUpFilter).then(
      (geoRegions) => {
        setRegionCodeOptionsTreeData(() => {
          const treeDataItems = geoRegions
            .map((geoRegion) => node2item(geoRegion))
            .sort((a, b) =>
              a.title === b.title ? 0 : a.title < b.title ? -1 : 1
            );

          return treeDataItems;
        });
      }
    );
  };

  //set treeDataRegion
  useEffect(
    function ueInitializeRegionCodeOptionsTreeData() {
      if (maxIdLevel === null) return;

      if (!edit) {
        //for new analysis
        initializeRegionCodeOptionsTreeDataRecursivelyNew();
      }

      if (edit && initialValues) {
        const idLevelMax = initialValues.idLevel;
        const levelUpFilter = initialValues.levelUp;

        initializeRegionCodeOptionsTreeDataRecursivelyEdit(
          idLevelMax,
          levelUpFilter
        );
      }
    },
    //eslint-disable-next-line
    [edit, maxIdLevel, initialValues]
  );

  useEffect(
    function ueInitializeRegionCodeFieldValueEdit() {
      if (
        edit &&
        initialValues &&
        initialValues.regionCode &&
        hasGeoRegionOptions
      ) {
        form.setFieldValue("regionCode", initialValues.regionCode);

        //set treeExpandedKeys
        setTreeExpandedKeys(() => {
          const expandedKeysGeoRegions = regionCodeOptionsTreeData
            .filter((item) => {
              if (!item) return false;

              const selectedRegionCode = initialValues.regionCode;
              return (
                selectedRegionCode.includes(item.id) &&
                selectedRegionCode !== item.id
              );
            })
            .map((item) => item.id)
            .sort((a, b) => (a === b ? 0 : a < b ? -1 : 1));

          return expandedKeysGeoRegions;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [edit, initialValues, hasGeoRegionOptions]
  );

  //handle user interaction
  //-------------------------

  const handleTreeExpand = (expandedKeys) => {
    setTreeExpandedKeys(expandedKeys);
  };

  const handleLoadDataRegions = async (node) => {
    const newIdLevel = node.idLevel + 1;
    const newLevelUp = node.id;

    const alreadyPresent =
      regionCodeOptionsTreeData.filter((opt) => {
        return opt.pId === node.id && opt.idLevel === node.idLevel + 1;
      })?.length > 0;

    if (alreadyPresent) return;

    const data = await getRegionsByLevelAndLevelUp(newIdLevel, newLevelUp)();

    if (data) {
      const newItems = data.geoRegions.map((geoRegion) => {
        geoRegion["idLevel"] = newIdLevel;
        return node2item(geoRegion);
      });

      setRegionCodeOptionsTreeData((prevTreeData) =>
        [...prevTreeData, ...newItems].sort((a, b) =>
          a.title === b.title ? 0 : a.title < b.title ? -1 : 1
        )
      );
    }
  };

  const handleChange = (value) => {
    if (!value) {
      setTreeExpandedKeys([]);
    } else {
      const idLevel = regionCodeOptionsTreeData.find(
        (treeDataItem) => treeDataItem.id === value
      )?.idLevel;
      form.setFieldValue("idLevel", idLevel);
    }
  };

  //jsx
  return (
    <>
      <Form.Item
        label={intl.formatMessage({ id: "label.region" })}
        name={"regionCode"}
        initialValue={undefined}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: "msg.input-required.regionCode",
            }),
          },
        ]}
        validateTrigger={"onSubmit"}
      >
        <TreeSelect
          treeDataSimpleMode
          style={{ width: "100%" }}
          showSearch
          allowClear={true}
          placeholder={
            !hasGeoRegionOptions ? (
              <LoadingRegionCodeOptions />
            ) : (
              intl.formatMessage({
                id: "label.FormItemsLocationsForProject.regionCode.TreeSelect.placeholder",
              })
            )
          }
          multiple={false}
          treeDefaultExpandAll={false}
          loadData={handleLoadDataRegions}
          treeData={regionCodeOptionsTreeData}
          treeExpandedKeys={treeExpandedKeys}
          treeNodeFilterProp={"title"}
          maxTagCount={"responsive"}
          onTreeExpand={handleTreeExpand}
          onChange={handleChange}
        />
      </Form.Item>
      <Form.Item
        name={"idLevel"}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: "msg.input-required.FormItemsLocationsForProject.idLevel",
            }),
          },
        ]}
        hidden={true}
        initialValue={undefined}
      >
        <InputNumber />
      </Form.Item>
    </>
  );
};

export default FormItemsLocationsForProject;
