/**
* VA-Team
*/
import "./LayersControl.css";
import {Button} from "../Button/Button";
import ReactDOM from "react-dom";
import React, {useState, useEffect} from "react";
import {Checkbox} from "antd";
import { WIDTH_PLUS } from "../../../utils/utils";

/**
 * A Mapbox control that uses a button and a react checkboxgroup elements to select which layer
 * is shown in a Mapbox map.
 */
class LayersControlByCheckbox_SimplePointMap {
    activeVariablesAliases;
    variablesAliases;
    setLastControlEvent;
    maxHeight;

    constructor (activeVariablesAliases, variablesAliases, setLastControlEvent, maxHeight) {
      this.activeVariablesAliases = JSON.parse(JSON.stringify(activeVariablesAliases)); // copy the array to avoid mutating it's original reference.  This is a deep copy.
      this.variablesAliases = JSON.parse(JSON.stringify(variablesAliases));  // copy the array to avoid mutating it's original reference.  This is a deep copy.
        this.setLastControlEvent = setLastControlEvent;
        this.maxHeight = maxHeight;
    };

    onAdd(map){
        //setting map
        this.map = map;

        //setting container element
        this.containerElement = document.createElement('div');
        this.containerElement.classList.add('mapboxgl-ctrl', 'div-ctrl');

        //setting button element
        this.buttonElement = Button('map-layers');
        this.buttonElement.title = "Hide/Show Layers";
        //appending button element to container element
        this.containerElement.appendChild(this.buttonElement);

        //creating container for Checkbox.Group
        this.divCheckboxGroupElement = document.createElement('div');
        this.divCheckboxGroupElement.classList.add('map-layers-div-checkbox-group');
        this.divCheckboxGroupElement.setAttribute('id',
            'custom-layers-control-checkbox-group-container');
        //css style rules of divCheckboxGroupElement
        const maxNumChars = Math.max(...(this.variablesAliases.map(variableAlias =>
            variableAlias.length)));
        const widthPlus = WIDTH_PLUS;
        const width = (maxNumChars+widthPlus)+'ch';
        const height = 'auto';
        const heightTop = '40px';
        this.divCheckboxGroupElement.style.display = 'none';
        this.divCheckboxGroupElement.style.width = width;
        this.divCheckboxGroupElement.style.height = height;
        this.divCheckboxGroupElement.style.borderRadius = '6px';
        this.divCheckboxGroupElement.style.backgroundColor = '#fff';
        this.divCheckboxGroupElement.style.position = 'relative';
        this.divCheckboxGroupElement.style.right = width;
        this.divCheckboxGroupElement.style.top = '-'+heightTop;
        this.divCheckboxGroupElement.style.maxHeight = this.maxHeight+'px';
        this.divCheckboxGroupElement.style.overflowY = 'auto';

        //rendering checkBoxGroupReactElement
        this.containerElement.appendChild(this.divCheckboxGroupElement);

        //rendering CheckBoxGroup
        ReactDOM.render(
          <CheckBoxGroup
            variablesAliases={this.variablesAliases}
            activeVariablesAliases={this.activeVariablesAliases}
            setLastControlEvent={this.setLastControlEvent}
            />,
          this.divCheckboxGroupElement
        );

        //setting button element's behavior on click event
        this.buttonElement.addEventListener('click', () => {
            //displaying select element
            this.divCheckboxGroupElement.style.display = 'block';

            let stylesControlRadioGroupContainerElement = document.getElementById(
                'custom-styles-control-radio-group-container');
            if (stylesControlRadioGroupContainerElement) {
                stylesControlRadioGroupContainerElement.style.display = 'none';
            }

            let colorSchemesControlRadioGroupContainerElement = document.getElementById(
                'custom-color-schemes-control-radio-group-container');
            if (colorSchemesControlRadioGroupContainerElement) {
                colorSchemesControlRadioGroupContainerElement.style.display = 'none'
            }
        });

        //setting divCheckboxGroupElement element's behavior on mouse leave event
        this.divCheckboxGroupElement.addEventListener('mouseleave', () => {
            //no displaying select element
            this.divCheckboxGroupElement.style.display = 'none';
        });

        return this.containerElement;
    }

    onRemove(){
        this.containerElement.parentNode?.removeChild(this.containerElement);
        this.map = undefined;
    }

    update(variablesAliases, activeVariablesAliases) {
      this.variablesAliases = JSON.parse(JSON.stringify(variablesAliases));
      this.activeVariablesAliases = JSON.parse(JSON.stringify(activeVariablesAliases));
      //updating css styles
      this.divCheckboxGroupElement = document.getElementById('custom-layers-control-checkbox-group-container');
      const maxNumChars = Math.max(...(this.variablesAliases.map(variableAlias =>
          variableAlias.length)));
      const widthPlus = WIDTH_PLUS;
      const width = (maxNumChars+widthPlus)+'ch';
      this.divCheckboxGroupElement.style.width = width;
      this.divCheckboxGroupElement.style.right = width;      

      //updating CheckBoxGroup
      ReactDOM.render(
        <CheckBoxGroup
          variablesAliases={this.variablesAliases}
          activeVariablesAliases={this.activeVariablesAliases}
          setLastControlEvent={this.setLastControlEvent}
          />,
        this.divCheckboxGroupElement
      );
    }
}

export default LayersControlByCheckbox_SimplePointMap;

/**
* Component to represent the layers in layers control
*/
const CheckBoxGroup = (props) => {
  const {
    variablesAliases,
    activeVariablesAliases,
    setLastControlEvent
  } = props;

  const notActiveVariablesAliases = variablesAliases.filter(variableAlias => 
    !activeVariablesAliases.includes(variableAlias));
  let actualVariablesAliases = [...activeVariablesAliases, ...notActiveVariablesAliases];
  

  const options = actualVariablesAliases.map((variableAlias, i) => {

      return {
        label: variableAlias,
        value: variableAlias,
      }
  });  

  const [value, setValue] = useState(activeVariablesAliases);
  useEffect(() => {
    setValue(activeVariablesAliases);
  }, [activeVariablesAliases]);


  const handleChange = (checkedValues) => {    
    setValue(checkedValues);
    if (setLastControlEvent !== null) setLastControlEvent({ event: 'layersControl', data: checkedValues });
  };

  return <Checkbox.Group
    id={'custom-control-checkbox-group-id'}
    style={{marginLeft: '5px'}}
    onChange={handleChange}
    value={value}
    options={options}
    />
};

