import {Breadcrumb, Layout} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import {FormattedMessage } from "react-intl";
import {connect, useDispatch} from "react-redux";
import {useEffect, useRef, useState} from "react";
import StylesControlByRadio
    from "../../utils/dataAnalysis/Plots/CustomMapboxControls/StylesControl/StylesControlByRadio";
import mapboxgl from "mapbox-gl";
import {getPopupDOMContentForPointsOfInterest} from "../surveyForms/getPopupDOMContentForPointsOfInterest";
import * as turf from "@turf/turf";
import {DATEFORMAT, getStatsPointMap} from "../../utils/utilData";
import Filters from "./Filters";


const {Content, Footer} = Layout;
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

const PointMap = (props) => {


    const optionsValuesStylesControl = [
        "streets",
        "satellite-streets"
    ]

    const mapContainerRef = useRef();
    const [mapStyle] = useState({
        label: 'Satellite streets',
        value: 'satellite-streets',
        url: "mapbox://styles/mapbox/satellite-streets-v11"
    });

    const dispatch = useDispatch();
    const [searchLoad, setSearchLoad] = useState(false);
    const [stylesControl, setStylesControl] = useState(new StylesControlByRadio(optionsValuesStylesControl, mapStyle, null, null));
    const [data, setData] = useState(null);


    useEffect(() => {
        setStylesControl(new StylesControlByRadio(optionsValuesStylesControl, mapStyle, null, null))
        //eslint-disable-next-line
    }, [mapStyle]);


    useEffect(() => {
        fillMap(data)
        //eslint-disable-next-line
    }, [data]);


    const onFinish = (values) => {
        setSearchLoad(true)
        let filterSearch = {};
        if (
            "dateRange" in values &&
            values.dateRange !== undefined &&
            values.dateRange !== null
        ) {
            filterSearch.dateStart = values.dateRange[0].format(DATEFORMAT);
            filterSearch.dateEnd = values.dateRange[1].format(DATEFORMAT);
        }
        if (values.regionCode) {
            const regionValues = values.regionCode?.split(';')
            filterSearch.idLevel = regionValues[0]
            filterSearch.regionCode = regionValues[1]
        }
        filterSearch.form = values.form
        dispatch(getStatsPointMap(filterSearch)).then(data => {
            if (data) {
                setSearchLoad(false)
                setData(data)
            }

        })
    }




    const fillMap = (data) => {
        const geojson = data?.area;
        const latitude = 0;
        const longitude = 0;
        let map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: mapStyle.url,
            center: [longitude, latitude],
            zoom: 2
        });

        // Add navigation control (the +/- zoom buttons)
        map.addControl(new mapboxgl.NavigationControl(), 'top-right');
        map.addControl(new mapboxgl.FullscreenControl(), 'top-right');
        map.addControl(stylesControl, 'top-right');
        const geolocate = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        })
        map.addControl(geolocate);
        map.on('load', () => {
            map.resize();
            if (geojson){
                map.addSource('region', {type: 'geojson', data: geojson});
                map.addLayer({
                    'id': 'region',
                    'type': 'line',
                    'source': 'region',
                    'layout': {
                        'visibility': 'visible'
                    },
                    'paint': {
                        'line-color': 'green',
                        'line-width': 2,
                    }
                });
                const bbox = turf.bbox(geojson);
                map.fitBounds(bbox, {padding: 20});
            }

            data?.points.forEach(point => {
                const feature = {
                    properties: {
                        name: point?.id +" - " + point?.date_created,
                    }
                }
                new mapboxgl.Marker({
                    color: data?.color, scale: 1,
                    draggable: false,
                    pitchAlignment: 'auto',
                    rotationAlignment: 'auto'
                })
                    .setLngLat([point?.longitude, point?.latitude])
                    .setPopup(
                        new mapboxgl.Popup({closeButton: false}) // add popups
                            .setDOMContent(getPopupDOMContentForPointsOfInterest(feature))
                    )
                    .addTo(map);
            })



        });

        return () => map.remove();
    }

   return (
        <Layout className="site-layout">
            <Content style={{margin: '0 16px'}}>
                <Breadcrumb style={{margin: '10px 0'}}>
                    <Breadcrumb.Item>
                        <HomeOutlined/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="menu.Analysis" defaultMessage="Analysis"/></span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <span><FormattedMessage id="label.PointMap" defaultMessage="Point Map"/></span>
                    </Breadcrumb.Item>

                </Breadcrumb>
                <Filters onFinish={onFinish} searchLoad={searchLoad}/>
                <div className="map-container" ref={mapContainerRef}
                     style={{height: 700, width: '100%'}}/>
            </Content>
            <Footer style={{textAlign: 'center'}}>©{new Date().getFullYear()}</Footer>
        </Layout>
   );
}


const mapStateToProps = state => ({

});

export default connect(mapStateToProps, {})(PointMap);