import "../../utils/dataAnalysis/utils/helpersForPopup.css";
import ReactDOM from "react-dom";
import { Image } from "antd";

//popup DOM element
export const getPopupDOMContentForPointsOfInterestRats = (feature) => {
  const popupContainerElement = document.createElement("div");
  popupContainerElement.classList.add("custom-popup-divContainerElement");

  const propertiesKeys = Object.keys(feature.properties);

  propertiesKeys.forEach((propertyKey) => {
    switch (propertyKey) {
      case "name":
        if (feature.properties?.name) {
          //displaying title
          const titleElement = get_titleElement(feature.properties.name);
          popupContainerElement.appendChild(titleElement);

          //line
          const lineElement = document.createElement("hr");
          lineElement.classList.add("custom-popup-lineElement");
          popupContainerElement.appendChild(lineElement);
        }
        break;
      case "photo":
        const isPhoto =
          feature.properties?.[propertyKey] !== null &&
          feature.properties?.[propertyKey] !== undefined;
        if (isPhoto) {
          const photoElement = get_imgElement(
            null,
            feature.properties[propertyKey]
          );
          popupContainerElement.appendChild(photoElement);
        }
        break;
      default:
        const variable = getVariable(propertyKey);
        let variableValue = feature.properties[propertyKey];
        if (propertyKey === "date") {
          variableValue = variableValue.replace("00:00:00", "");
          variableValue = variableValue.trim();
        }
        if (variable !== null) {
          const isElement =
            feature.properties?.[propertyKey] !== null &&
            feature.properties?.[propertyKey] !== undefined;
          if (isElement) {
            const pElement = get_pElement(variable, variableValue);
            popupContainerElement.appendChild(pElement);
          }
        }
    }
  });

  return popupContainerElement;
};

const getVariable = (propertyKey) => {
  switch (propertyKey) {
    case 'name':
        break;
    case 'photo':
        break;
    case "status":
      return "Status";
    case "description":
      return "Description";
    case "city":
      return "City";
    case "date":
      return "Date";
    default:
      console.log(`propertyKey: ${propertyKey} is not implemented`);
      return null;
  }
};

const get_titleElement = (title) => {
  const pElement = document.createElement("p");
  pElement.classList.add("custom-popup-titleElement");

  const spanElementAlias = document.createElement("span");
  spanElementAlias.classList.add("custom-popup-title");
  spanElementAlias.innerText = title;
  pElement.appendChild(spanElementAlias);

  return pElement;
};

const get_pElement = (variableAlias, variableValue) => {
  const pElement = document.createElement("p");
  pElement.classList.add("custom-popup-pElement");

  const spanElementAlias = document.createElement("span");
  spanElementAlias.classList.add("custom-popup-alias");
  spanElementAlias.innerText =
    variableAlias === "" ? variableAlias : variableAlias + ": ";
  pElement.appendChild(spanElementAlias);

  const spanElementValue = document.createElement("span");
  spanElementValue.classList.add("custom-popup-value");
  spanElementValue.innerText = variableValue;
  pElement.appendChild(spanElementValue);

  return pElement;
};

const get_imgElement = (variable, variableValue) => {
  if (
    variableValue !== undefined &&
    variableValue !== null &&
    variableValue !== ""
  ) {
    const pElement = document.createElement("p");
    pElement.classList.add("custom-popup-pElement");

    if (variable) {
      const spanElementAlias = document.createElement("span");
      spanElementAlias.classList.add("custom-popup-alias");
      spanElementAlias.innerText = variable + ": ";
      pElement.appendChild(spanElementAlias);
    }

    const imgDivContainer = document.createElement("div");
    imgDivContainer.classList.add("custom-popup-imgDivContainer");

    const imgJSX = <Image width={150} src={`data:${variableValue}`} />;
    ReactDOM.render(imgJSX, imgDivContainer);

    const imageElement = document.createElement("div");
    imageElement.classList.add("custom-popup-imageElement");

    if (variable) {
      imageElement.appendChild(pElement);
    }
    imageElement.appendChild(imgDivContainer);

    return imageElement;
  } else {
    return get_pElement(variable, variableValue);
  }
};
