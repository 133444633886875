import {Button, DatePicker, Divider, Form, message} from "antd";
import {FormattedMessage, useIntl} from "react-intl";
import {connect} from "react-redux";
import {useEffect} from "react";
import {DATEFORMAT} from "../../utils/utilData";
import moment from "moment/moment";

const {RangePicker} = DatePicker;


const Filters311 = (props) => {

    const intl = useIntl();
    const [form] = Form.useForm();


    useEffect(() => {

        if (props.error) {
            const error = () => {
                message.error(props.error).then(props.clearSurveyFormError());
            };
            error();
        }
        // eslint-disable-next-line
    }, [props.error]);

    useEffect(() => {
        if (!props?.initDateRange) return;

        form.setFieldValue('dateRange', [moment(props.initDateRange[0]), moment(props.initDateRange[1])]);

    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props?.initDateRange])

    return (
        <>
            <Divider
                orientation="left"
                plain
                orientationMargin="0"
            >
                <FormattedMessage id="label.filters" defaultMessage="Filters"/>
            </Divider>

            <Form
                layout="inline"
                name="project_form"
                size="large"
                initialValues={{active: true}}
                form={form}
                onFinish={props.onFinish}
                onFinishFailed={props.onFinishFailed}
            >

                <Form.Item
                    name="dateRange" key="dateRange"
                    label={intl.formatMessage({id: "label.dates"})}
                    rules={[
                        {
                            required: false,
                            message: intl.formatMessage({id: "msg.input-required"}),
                        },
                    ]}
                >
                    <RangePicker format={DATEFORMAT} disabledDate={disabledDate}/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={props.searchLoad}>
                        <FormattedMessage id="label.search" defaultMessage="Search"/>
                    </Button>
                </Form.Item>
            </Form>
            <Divider/>
        </>

    );
}


function disabledDate(current) {
    // Can not select days after today and today
    return current && current > moment().endOf("day");
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, {})(Filters311);